import { IconProps } from "../features/entities/general";

const Warning = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2226 12.2934V19.1481M2.4511 24.0199C1.16913 26.1846 2.77233 28.8889 5.33478 28.8889H27.1105C29.6714 28.8889 31.2746 26.1846 29.9942 24.0199L19.1078 5.62347C17.8258 3.45885 14.6194 3.45885 13.3375 5.62347L2.4511 24.0199Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2304 23.5H16.22"
          stroke={color}
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Warning;

