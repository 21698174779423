import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Modal from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import { CompanyDuplicateResponse } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Email from "@hellodarwin/icons/dist/icons/Email";
import Table, { TableProps } from "antd/es/table";
import { useEffect, useState } from "react";
import { useClientApi } from "../../../features/api/use-client-api";
import { CompanyDetailsFormValues } from "./client-onboarding-profile-page";

type DuplicateCompanyModalProps = {
  duplicateCompanies: CompanyDuplicateResponse[];
  visible: boolean;
  handleClose: () => void;
  handleOnboard?: (values: CompanyDetailsFormValues) => void;
  form: any;
};

const DuplicateCompanyModal = ({
  duplicateCompanies,
  visible,
  handleClose,
  handleOnboard,
  form,
}: DuplicateCompanyModalProps) => {
  const { t } = useTranslation();
  const api = useClientApi();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (emailSent) {
    }
  }, [emailSent]);

  const requestToJoin = async (companyId: string) => {
    try {
      setLoading(true);
      await api.sendRequestToJoinCompanyEmail(companyId);
      setEmailSent(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleAfterClose = () => {
    if (emailSent) {
      const values = form.getFieldsValue();
      handleOnboard && handleOnboard(values);
    }
    setEmailSent(false);
    setLoading(false);
  };

  type DuplicateDataType = {
    key: string;
    company_name: string;
    domain: string;
    email: string;
  };

  const columns: TableProps<DuplicateDataType>["columns"] = [
    {
      title: t("onboarding|duplicate_company_modal.company_name"),
      dataIndex: "company_name",
      key: "company_name",
      width: 300,
      render: (text: string) => {
        return (
          <Typography>
            {text.length > 25 ? `${text.slice(0, 20)}...` : text}
          </Typography>
        );
      },
    },
    {
      title: t("onboarding|duplicate_company_modal.domain"),
      dataIndex: "domain",
      key: "domain",
      width: 300,
      render: (text: string) => {
        return (
          <a href={`${text}`} target="_blank" rel="noreferrer">
            {text.length > 25 ? `${text.slice(0, 20)}...` : text}
          </a>
        );
      },
    },
    {
      title: t("onboarding|duplicate_company_modal.email"),
      dataIndex: "email",
      key: "email",
      width: 300,
      render: (text: string) => {
        return (
          <Typography>
            {text.length > 25 ? `${text.slice(0, 20)}...` : text}
          </Typography>
        );
      },
    },
    {
      render: (record) => {
        return (
          <Button
            size="small"
            onClick={() => {
              requestToJoin(record.key);
            }}
          >
            {t("onboarding|duplicate_company_modal.request_join")}
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      header={
        loading || emailSent ? null : (
          <Typography elementTheme="h6">
            {t("onboarding|duplicate_company_modal.title")}
          </Typography>
        )
      }
      open={visible}
      handleCancel={handleClose}
      afterClose={handleAfterClose}
      size={emailSent || loading ? "small" : "large"}
    >
      {loading ? (
        <Div
          flex={"column"}
          gap={32}
          justify={"center"}
          style={{ height: 100 }}
        >
          <Loading />
        </Div>
      ) : emailSent ? (
        <Div
          flex="column"
          justify="center"
          align="center"
          style={{ padding: "1rem" }}
        >
          <Email height={64} width={64} />
          <Typography.Title
            level={2}
            elementTheme="h4"
            medium
            textAlign="center"
            style={{ marginBottom: 10 }}
          >
            {t("onboarding|duplicate_company_modal.email_sent")}
          </Typography.Title>
          <Typography color={theme.colors.grey_2} textAlign="center">
            {t("onboarding|duplicate_company_modal.email_sent_description")}
          </Typography>
        </Div>
      ) : (
        <Div flex="column" gap={32} justify="center">
          <Table
            pagination={false}
            columns={columns}
            dataSource={duplicateCompanies.map((comp) => {
              return {
                key: comp.company_id,
                company_name: comp.company_name,
                domain: comp.domain,
                email: comp.email,
              };
            })}
          ></Table>
          <Div flex="row" justify="flex-end">
            <Button
              onClick={() => handleClose()}
              size="small"
              style={{ marginRight: 8 }}
            >
              {t("onboarding|duplicate_company_modal.cancel")}
            </Button>
            <Button
              onClick={() => {
                const values = form.getFieldsValue();
                handleOnboard && handleOnboard(values);
              }}
              size="small"
            >
              {t("onboarding|duplicate_company_modal.createCompany")}
            </Button>
          </Div>
        </Div>
      )}
    </Modal>
  );
};

export default DuplicateCompanyModal;

