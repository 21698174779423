import Drawer from "antd/es/drawer";
import { FundingExplorerFilterProps } from "..";
import { useTranslation } from "../../../../plugins/i18n";
import Button from "../../../common/button";
import Div from "../../../common/div";
import { Tablet } from "../../../common/Media";
import Typography from "../../../common/typography";
import FilterDropdownDesktopDrawer from "./filter-dropdown-desktop-drawer";
import FilterDropdownForm from "./filter-dropdown-form";

export interface FiltersDropdownProps extends FundingExplorerFilterProps {
  isOpen: boolean;
  handleClose: () => void;
}

const FiltersDropdown = (props: FiltersDropdownProps) => {
  const { t } = useTranslation();
  const { isOpen, handleClose, resetFilter } = props;
  return (
    <>
      <Tablet direction="up">
        <FilterDropdownDesktopDrawer open={isOpen} handleClose={handleClose}>
          <FilterDropdownForm {...props} />
        </FilterDropdownDesktopDrawer>
      </Tablet>
      <Tablet direction="down">
        <Drawer
          title={
            <Div flex="row" gap={8} justify="space-between" align="center">
              <Typography elementTheme="subtitle2">
                {t(`grants|fundingExplorerFilter.advancedFilters`)}
              </Typography>{" "}
              <Button
                onClick={resetFilter}
                isLink
                size="medium"
                style={{ minHeight: "auto" }}
              >
                {t(`grants|fundingExplorerFilter.resetFilter`)}
              </Button>
            </Div>
          }
          open={isOpen}
          onClose={handleClose}
          styles={{ wrapper: { width: "100vw" } }}
        >
          <FilterDropdownForm {...props} />
        </Drawer>
      </Tablet>
    </>
  );
};

export default FiltersDropdown;

