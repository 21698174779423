import { IconProps } from "../features/entities/general";

const Dots = ({
  width = "4",
  height = "17",
  color = "currentColor",
  className,
  style,
  onClick,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    className={className}
    onClick={onClick}
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.8"
    stroke={color}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);

export default Dots;

