import { IconParams } from "@hellodarwin/icons/dist/backgrounds/icon-entities";
import classNames from "classnames";
import React, { CSSProperties, PropsWithChildren } from "react";
import { AppType } from "../../../../features/entities/core-entities";
import AppFooter from "../app-layout/app-footer";
import BackgroundIcon from "./BackgroundIcon";
import { PageContainerStyled } from "./styles";

export type PageContainerProps = {
  backgroundColor?: string;
  containerBackgroundColor?: string;

  overflowHidden?: boolean;
  cap?: number | string;
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
  };
  className?: string;
  noPadding?: boolean;
  icons?: IconParams[];
  withoutPaddingRight?: boolean;
  withoutPaddingHorizontal?: boolean;
  app: AppType;
} & PropsWithChildren;

const PageContainer = ({
  styles,
  overflowHidden,
  children,
  backgroundColor,
  containerBackgroundColor,
  className,
  noPadding,
  icons,
  withoutPaddingRight,
  withoutPaddingHorizontal,
  app,
}: PageContainerProps) => {
  const stylingBasics: CSSProperties = {
    width: "100%",
    background: containerBackgroundColor,
    position: "relative",
    overflow: overflowHidden ? "hidden" : "initial",
    zIndex: 1,
    height: "fit-content",
    ...styles?.container,
  };

  const classes = classNames("PageContainer", {
    [`${className}`]: className,
  });

  return (
    <div style={stylingBasics}>
      <PageContainerStyled
        className={classes}
        style={styles?.content}
        $isAdmin={app === "admin"}
        $withoutPaddingRight={withoutPaddingRight}
        $withoutPaddingHorizontal={withoutPaddingHorizontal}
        $withoutPadding={noPadding}
        $backgroundColor={backgroundColor}
      >
        <div className="background">
          {React.Children.toArray(
            icons?.map((iconParams, i) => (
              <BackgroundIcon
                iconParams={iconParams}
                key={`icon-${i}-${iconParams.side}`}
              />
            ))
          )}
        </div>
        {children}
        <AppFooter />
      </PageContainerStyled>
    </div>
  );
};

export default PageContainer;

