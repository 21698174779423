import UploadIcon from "@hellodarwin/icons/dist/icons/Upload";
import ImgCrop, { ImgCropProps } from "antd-img-crop";
import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import InputNumber from "antd/es/input-number";
import notification from "antd/es/notification";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useState } from "react";
import { CropperProps } from "react-easy-crop";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Typography from "../../../common/typography";
import validateFile, { getFileTypes } from "../../utils/validate-file";
import StepContainer from "./StepContainer";
import { StepContentStyled, UploadStyled } from "./styles";

interface ImgCropPropsWhitoutChildren extends Omit<ImgCropProps, "children"> {}

type UploaderProps = {
  fileList: UploadFile[];
  name: string;
  width: number;
  height: number;
  handleChange: (fileList: RcFile[]) => void;
};

const Uploader = ({
  fileList,
  name,
  width,
  height,
  handleChange,
}: UploaderProps) => {
  const [isValidFile, setIsValidFile] = useState(true);
  const [isBlackBG, setIsBlackBG] = useState(false);
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoomScale, setZoomScale] = useState(0.05);
  const [zoom, onZoomChange] = useState(0);

  const { t } = useTranslation();
  const theme = useTheme();

  const Step1 = () => (
    <StepContainer
      label={t("step", { count: 1 }).toUpperCase()}
      description={t("profile|assetsModal.chooseBg")}
    >
      <StepContentStyled>
        <div>
          <Checkbox checked={isBlackBG} onChange={() => setIsBlackBG(true)}>
            <Typography elementTheme="body2">
              {t("profile|assetsModal.blackBg")}
            </Typography>
          </Checkbox>
        </div>
        <div>
          <Checkbox checked={!isBlackBG} onChange={() => setIsBlackBG(false)}>
            <Typography elementTheme="body2">
              {t("profile|assetsModal.whiteBg")}
            </Typography>
          </Checkbox>
        </div>
        <div>
          <Typography elementTheme="body2">
            {t("profile|assetsModal.minZoom")}
          </Typography>
          <InputNumber
            value={zoomScale}
            onChange={(value) => setZoomScale(value)}
          />
        </div>
      </StepContentStyled>
    </StepContainer>
  );

  const Step2 = () => {
    const beforeUpload: UploadProps["beforeUpload"] = (file) => {
      const isValid = validateFile(file);
      if (isValid) {
        if (isValidFile) {
          handleChange([file]);
        } else {
          notification.error({ type: "error", message: "Upload failed" });
        }
      }
      setIsValidFile(isValid);
      return file;
    };

    const cropperProps: CropperProps = {
      objectFit: "vertical-cover",
      crop,
      zoom,
      rotation: 0,
      aspect: 0,
      zoomSpeed: 0,
      minZoom: 0,
      maxZoom: 1,
      onCropChange,
      onZoomChange,
      cropShape: "rect",
      style: {
        containerStyle: {
          background: isBlackBG ? "black" : "white",
        },
        cropAreaStyle: {
          color: isBlackBG ? "rgba(255, 255, 2555, 0.5)" : undefined,
        },
      },
      onCropComplete: (croppedArea, croppedAreaPixels) => {},

      classes: {},
      restrictPosition: false,

      mediaProps: { alt: name },
    };

    const imgCropProps: ImgCropPropsWhitoutChildren = {
      quality: 1,
      fillColor: isBlackBG ? "black" : "white",
      modalTitle: t("profile|assetsModal.editImage"),
      modalOk: t("profile|assetsModal.crop"),
      modalCancel: t("profile|assetsModal.cancel"),
      minZoom: zoomScale,
      maxZoom: 2,
      showGrid: true,
      cropperProps,
      modalWidth: 600,

      modalProps: {
        wrapProps: {
          style: { zIndex: 3100 },
        },
        styles: {
          mask: { zIndex: 3100 },

          content: {
            background: theme.colors.white_1,
            borderRadius: 8,
          },

          header: {
            fontFamily: "Figtree",
          },
        },
      },
      aspect: width / height,
    };

    const uploadProps: UploadProps = {
      className: "assets-uploader",
      name,
      fileList,
      beforeUpload: beforeUpload,
      maxCount: 1,
      multiple: false,
      onChange: (info) => {
        console.info(info);
      },
      customRequest(options) {
        setTimeout(() => {
          if (options.onSuccess) {
            options.onSuccess("ok", new XMLHttpRequest()), 0;
          }
        }, 500);
      },
    };
    return (
      <StepContainer
        label={t("step", { count: 2 }).toUpperCase()}
        description={t("profile|assetsModal.deposit", {
          type: t(`provider-profile|assets.${name}`),
        })}
      >
        <ImgCrop {...imgCropProps}>
          <UploadStyled {...uploadProps}>
            <UploadIcon width={100} height={100} />
            <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
              <Typography
                elementTheme="body3"
                textAlign="center"
                color={theme.colors.grey_2}
              >
                {t("upload.resolution", {
                  width: width,
                  height: height,
                })}
              </Typography>
              <Typography
                elementTheme="body3"
                textAlign="center"
                color={theme.colors.grey_2}
              >
                {" "}
                {t("upload.fileSize")}
              </Typography>
              <Typography
                elementTheme="body3"
                textAlign="center"
                color={theme.colors.grey_2}
              >
                {t("upload.allowedFile", {
                  types: getFileTypes().join(", "),
                })}
              </Typography>
            </div>
          </UploadStyled>
        </ImgCrop>
      </StepContainer>
    );
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Step1 />
      <Step2 />
    </div>
  );
};

export default Uploader;

Uploader.Demo = () => {
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const handleUploadChange = (newFileList: RcFile[]) => {
    setFileList(newFileList);
  };

  return (
    <Form layout="vertical">
      <Uploader
        fileList={fileList}
        name={"logo"}
        width={200}
        height={200}
        handleChange={handleUploadChange}
      />
    </Form>
  );
};

