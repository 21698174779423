import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";

export interface ProfileFormFooterProps {
  handleFinish: () => void;
  handleReset?: () => void;
  isSmall?: boolean;
}

const ProfileFormFooter = ({
  handleFinish,
  handleReset,
  isSmall,
}: ProfileFormFooterProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Div
      flex="row"
      gap={24}
      align="center"
      justify="flex-end"
      tablet={{
        flex: "column-reverse",
        align: "center",
        justify: "center",
        style: {
          position: "fixed",
          bottom: 0,
          left: 0,
          padding: "16px 54px",
          width: "100%",
          borderRadius: "8px 8px 0 0",
          borderTop: `1px solid ${theme.colors.grey_5}`,
          borderLeft: `1px solid ${theme.colors.grey_5}`,
          borderRight: `1px solid ${theme.colors.grey_5}`,
          background: `${theme.colors.white_1}bb`,
          backdropFilter: "blur(2px)",
        },
        gap: 8,
      }}
      fitContent={isSmall}
    >
      {!!handleReset && (
        <Button
          onClick={handleReset}
          size={"small"}
          defaultStyle={theme.colors.white_1}
          tablet={{ style: { width: "100%" } }}
        >
          {t("button|cancel")}
        </Button>
      )}
      <Button
        onClick={handleFinish}
        size={"small"}
        tablet={{ style: { width: "100%" } }}
      >
        {t("button|save")}
      </Button>
    </Div>
  );
};

export default ProfileFormFooter;

