import Close from "@hellodarwin/icons/dist/icons/Close";
import { Link } from "react-router-dom";
import { Company } from "../../../features/entities";
import getShortId from "../../../features/helpers/get-short-id";
import { useTheme } from "../../../plugins/styled";
import Div from "../../common/div";
import Typography from "../../common/typography";

interface FundingExplorerCompanyInfoProps {
  company?: Company;
  removeCompany: () => void;
}
const FundingExplorerCompanyInfo = ({
  company,
  removeCompany,
}: FundingExplorerCompanyInfoProps) => {
  const theme = useTheme();

  if (!company) return <></>;
  return (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      gap={24}
      backgroundColor={theme.colors.white_2}
      style={{ padding: 24 }}
    >
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Company Id</Typography>
        <Link target="_blank" to={`/companies/${company?.company_id}`}>
          {getShortId(company!.company_id)}
        </Link>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Name</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.name}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Industry</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.industry_sector
            ? company?.industry_sector
            : "No industry specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Company Size</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.size ? company?.size : "No size specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Region</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.province ? company?.province : "No region specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Annual Revenue</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.annual_revenue
            ? company?.annual_revenue
            : "No annual revenue specified"}
        </Typography>
      </Div>
      <Div
        alignSelf="flex-end"
        onClick={removeCompany}
        style={{ cursor: "pointer", width: 16 }}
      >
        <Close width={16} height={16} />
      </Div>
    </Div>
  );
};
export default FundingExplorerCompanyInfo;
