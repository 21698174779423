import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/filter/types";
import FundingExplorerListPageLayout from "@hellodarwin/core/lib/components/grants/funding-explorer-list-page";
import FundingExplorerCompanyInfo from "@hellodarwin/core/lib/components/grants/funding-explorer/funding-explorer-company-info";
import useApplyFundingExplorerFilter from "@hellodarwin/core/lib/components/grants/funding-explorer/hooks/apply-filters";
import Loading from "@hellodarwin/core/lib/components/loading";
import { Company } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Empty from "antd/es/empty";
import Form from "antd/es/form";
import Pagination from "antd/es/pagination";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import { QueryFundingExplorerProps } from "../../features/api/client-api-entities";
import {
  fetchBestGrantProjects,
  selectBestGrantProjects,
} from "../../features/api/slices/grants-projects-slice";
import {
  fetchFundingExplorerTotalAmount,
  fetchGrantFinancingType,
  fetchGrantServices,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectFundingExplorerTotalAmount,
  selectGrantFinancingType,
  selectGrantService,
  selectGrantsIsLoading,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectorsForFundingExplorer,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";
import GrantsDirectoryTotal from "./grants-directory-total";

const ClientFundingExplorer = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const [, setTextQuery] = useState<string | null>(null);
  const [company, setCompany] = useState<Company>();
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      selectedIndustries || []
    )
  );
  const provinces = useAppSelector(selectProvinces);
  const services = useAppSelector(selectGrantService);

  const { activePage, goToPage, clearAllParams, pageSize } = usePagination();

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const totalAmount = useAppSelector(selectFundingExplorerTotalAmount);
  const isLoading = useAppSelector(selectGrantsIsLoading);
  const bestProjects = useAppSelector(selectBestGrantProjects);

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [oldFilterValues, setOldFilterValues] =
    useState<FundingExplorerFilterValues>();

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantServices({ api, locale: selectedLocale }));
    dispatch(fetchBestGrantProjects({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const compareFilterValues = (
    newValues: FundingExplorerFilterValues
  ): [string, string] => {
    for (const key in oldFilterValues) {
      if (oldFilterValues.hasOwnProperty(key)) {
        const filterKey = key as keyof FundingExplorerFilterValues;
        const oldValue = oldFilterValues[filterKey];
        const newValue = newValues[filterKey];

        if (oldValue !== newValue) {
          return [filterKey, `${newValue}`];
        }
      }
    }

    return ["", ""];
  };

  const fetch = async () => {
    try {
      const filterValues = filterform.getFieldsValue();
      setOldFilterValues(filterValues);
      const filterChanges = compareFilterValues(filterValues);

      const queryProps: QueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        ...filterValues,
        selectedProjects,
        fieldChanged: filterChanges[0],
        valueChanged: filterChanges[1],
      };

      dispatch(queryFundingExplorer({ api, ...queryProps }));
      dispatch(fetchFundingExplorerTotalAmount({ api, ...queryProps }));
    } catch (e: any) {
      console.error(e);
    }
  };

  const applyFilter = useApplyFundingExplorerFilter({
    fetch,
    form: filterform,
    selectedIndustries,
    setSelectedIndustries,
  });

  useEffect(() => {
    applyFilter({
      isReset: true,
      isInit: true,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilter({
      isReset: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  useEffect(() => {
    applyFilter({
      isReset: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize]);

  const onPageChange = async (page: number, pageSize: number) => {
    goToPage(page, pageSize);
  };

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    applyFilter({
      isReset: true,
    });
  };

  const removeCompany = () => {
    filterform.resetFields();
    setCompany(undefined);
    applyFilter();
  };

  const resetFilter = () => {
    filterform.resetFields();
    setSelectedProjects([]);
    clearAllParams(0, 10);
    setSelectedIndustries([]);
    setTextQuery("");
    applyFilter();
  };

  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );

  return (
    <PageLayout
      app="client"
      title={t("navigation.fundingExplorer")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
        },
      ]}
      actions={
        hasGrants ? (
          <GrantsDirectoryTotal totalAmount={totalAmount} grants={grants} />
        ) : undefined
      }
      styles={{ container: { overflow: "visible" } }}
    >
      <FundingExplorerCompanyInfo
        company={company}
        removeCompany={removeCompany}
      />

      <FundingExplorerListPageLayout
        filterform={filterform}
        onTextQueryChange={onTextQueryChange}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        selectedIndustries={selectedIndustries}
        setSelectedIndustries={setSelectedIndustries}
        bestProjects={bestProjects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      >
        <Container>
          {isLoading ? (
            <Loading />
          ) : hasGrants ? (
            React.Children.toArray(
              grants.map((grant, index) => <GrantCard {...grant} key={index} />)
            )
          ) : (
            <Empty description={t("grants|fundingExplorerFilter.noResult")} />
          )}

          <Div flex="row" justify="center">
            <Pagination
              total={hasGrants ? grants[0]?.total_count : 0}
              current={activePage}
              onChange={(page, pageSize) => onPageChange(page, pageSize)}
              pageSize={pageSize}
            />
          </Div>
        </Container>
      </FundingExplorerListPageLayout>
    </PageLayout>
  );
};

export default ClientFundingExplorer;

