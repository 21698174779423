import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import GreenCheck from "@hellodarwin/icons/dist/icons/GreenCheck";

interface SubmitProjectSuccessProps {
  handleClose: () => void;
}
const SubmitProjectSuccess = ({ handleClose }: SubmitProjectSuccessProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      flex={"column"}
      justify={"center"}
      align={"center"}
      className="fade-in"
      gap={32}
    >
      <GreenCheck width={200} />

      <Div flex="column" gap={24} align="center">
        <Div flex="column" gap={12} align="center">
          <Typography elementTheme="subtitle1" bold fitContent>
            {t("project|projectForm.successModal.title")}
          </Typography>
          <Typography
            elementTheme="body2"
            className={"hd-2-mb"}
            textAlign={"center"}
            fitContent
          >
            {t("project|projectForm.successModal.text")}
          </Typography>
        </Div>
        <Button
          onClick={handleClose}
          defaultStyle={theme.colors.purple_1}
          fitContent
        >
          Ok
        </Button>
      </Div>
    </Div>
  );
};

export default SubmitProjectSuccess;

