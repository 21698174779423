import Propulsion from "@hellodarwin/icons/dist/icons/Propulsion";
import Search from "@hellodarwin/icons/dist/icons/Search";
import Service from "@hellodarwin/icons/dist/icons/Service";
import Divider from "antd/es/divider";
import { ReactNode, useState } from "react";
import { useTranslation } from "../../../plugins/i18n";
import { useTheme } from "../../../plugins/styled";
import Button from "../../common/button";
import Container from "../../common/container";
import Div from "../../common/div";
import List, { ListGridType } from "../../common/List";
import Typography from "../../common/typography";
import ContactAdvisorModal from "./contact-advisor-modal";

interface CardInfos {
  icon: ReactNode;
  title: string;
  text: string;
}
const ProgramEmptyState = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen(!modalOpen);

  const StepCard = ({ item }: { item: CardInfos }) => (
    <Div
      flex="column"
      gap={17}
      align="center"
      style={{
        padding: 20,
        flexShrink: 0,
        flexGrow: 0,
        flex: 1,
        width: 320,
        height: "100%",
      }}
      borderRadius={4}
      backgroundColor={theme.colors.white_1}
      borderColor={theme.colors.grey_5}
    >
      {item.icon}

      <Typography.Title level={4} elementTheme="h6" textAlign="center">
        {item.title}
      </Typography.Title>
      <Typography elementTheme="body2" textAlign="center">
        {item.text}
      </Typography>
    </Div>
  );

  const Header = () => (
    <Div flex="column" gap={24} style={{ maxWidth: 900 }}>
      <Typography.Title level={1} elementTheme="h2" textAlign="center" noMargin>
        {t("propulsion|empty.title")}
        <Typography.Highlight color={theme.colors.green_2}>
          {t("propulsion|empty.programName")}
        </Typography.Highlight>
      </Typography.Title>

      <Typography
        color={theme.colors.purple_1}
        elementTheme="subtitle2"
        textAlign="center"
      >
        {t("propulsion|empty.subtitle")}
      </Typography>
    </Div>
  );

  const Content = () => {
    const grid: ListGridType = {
      gutter: 28,
      column: 3,
      sm: 1,
      md: 1,
      lg: 3,
      xl: 3,
      xxl: 3,
    };

    const items = [
      {
        icon: <Search width={20} height={20} />,
        title: t("propulsion|empty.card1.title"),
        text: t("propulsion|empty.card1.text"),
      },
      {
        icon: <Service width={20} height={20} />,
        title: t("propulsion|empty.card2.title"),
        text: t("propulsion|empty.card2.text"),
      },
      {
        icon: <Propulsion width={20} height={20} />,
        title: t("propulsion|empty.card3.title"),
        text: t("propulsion|empty.card3.text"),
      },
    ];

    return (
      <div
        style={{ position: "relative", width: "fit-content", margin: "auto" }}
      >
        <Divider
          style={{
            borderColor: theme.colors.grey_4,
            position: "absolute",
            top: "50%",
          }}
        />
        <List
          grid={grid}
          styles={{ container: { minWidth: theme.breakpoints.tablet } }}
          dataSource={items}
          renderItem={(item) => (
            <List.Item style={{ height: "100%" }}>
              <StepCard item={item} />
            </List.Item>
          )}
        />
      </div>
    );
  };

  const Footer = () => (
    <Div flex="column" gap={32} align="center" style={{ maxWidth: 400 }}>
      <Button
        defaultStyle={theme.colors.green_1}
        fitContent
        tablet={{ fullWidth: true }}
        style={{ height: 40 }}
        onClick={handleModal}
      >
        {t("propulsion|empty.cta")}
      </Button>
    </Div>
  );

  return (
    <>
      <Container style={{ paddingTop: 54, paddingBottom: 40 }}>
        <Div
          fitContent
          flex="column"
          gap={50}
          align="center"
          style={{ margin: "auto" }}
          tablet={{ style: { width: "100%" } }}
        >
          <Header />
          <Content />
          <Footer />
        </Div>
      </Container>
      <ContactAdvisorModal open={modalOpen} handleCancel={handleModal} />
    </>
  );
};

export default ProgramEmptyState;

