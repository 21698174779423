import { IconProps } from "../features/entities/general";

const PDF = ({ width, height, style, className, onClick }: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1004 15.7008V12.5508C22.1004 11.4767 21.669 10.4465 20.901 9.687C20.133 8.92748 19.0915 8.50078 18.0054 8.50078H16.1854C15.8234 8.50078 15.4762 8.35855 15.2202 8.10537C14.9642 7.8522 14.8204 7.50882 14.8204 7.15078V5.35078C14.8204 4.27665 14.389 3.24652 13.621 2.487C12.853 1.72748 11.8115 1.30078 10.7254 1.30078H8.45039M11.1804 1.30078H5.26539C4.51191 1.30078 3.90039 1.90558 3.90039 2.65078V23.3508C3.90039 24.096 4.51191 24.7008 5.26539 24.7008H20.7354C21.4889 24.7008 22.1004 24.096 22.1004 23.3508V12.1008C22.1004 9.23644 20.9499 6.48942 18.902 4.46403C16.8541 2.43863 14.0766 1.30078 11.1804 1.30078Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 22V18H9.58389C9.90837 18 10.1806 18.0573 10.4006 18.1719C10.6219 18.2865 10.789 18.444 10.9017 18.6445C11.0158 18.8438 11.0729 19.0703 11.0729 19.3242C11.0729 19.5807 11.0158 19.8086 10.9017 20.0078C10.7876 20.207 10.6192 20.3639 10.3965 20.4785C10.1737 20.5918 9.89943 20.6484 9.57358 20.6484H8.52384V20.0527H9.47046C9.6602 20.0527 9.81556 20.0215 9.93655 19.959C10.0575 19.8965 10.1469 19.8105 10.2047 19.7012C10.2638 19.5918 10.2933 19.4661 10.2933 19.3242C10.2933 19.1823 10.2638 19.0573 10.2047 18.9492C10.1469 18.8411 10.0569 18.7572 9.93449 18.6973C9.8135 18.6361 9.65745 18.6055 9.46633 18.6055H8.76513V22H8Z"
          fill="currentColor"
        />
        <path
          d="M13.343 22H11.9117V18H13.3719C13.7912 18 14.1514 18.0801 14.4525 18.2402C14.755 18.3991 14.9874 18.6276 15.1496 18.9258C15.3119 19.224 15.393 19.5807 15.393 19.9961C15.393 20.4128 15.3112 20.7708 15.1476 21.0703C14.9853 21.3698 14.7509 21.5996 14.4443 21.7598C14.1391 21.9199 13.772 22 13.343 22ZM12.6769 21.373H13.3059C13.6001 21.373 13.8455 21.3223 14.0421 21.2207C14.2388 21.1178 14.3866 20.9648 14.4855 20.7617C14.5845 20.5573 14.634 20.3021 14.634 19.9961C14.634 19.6901 14.5845 19.4362 14.4855 19.2344C14.3866 19.0313 14.2401 18.8796 14.0463 18.7793C13.8538 18.6777 13.6145 18.627 13.3286 18.627H12.6769V21.373Z"
          fill="currentColor"
        />
        <path
          d="M16.2942 22V18H19V18.6074H17.0593V19.6934H18.8144V20.3008H17.0593V22H16.2942Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default PDF;

