import DarwinError from "@hellodarwin/icons/dist/graphics/DarwinError";
import { AppType } from "../../features/entities";
import { useTranslations } from "../../features/providers/translations-provider";
import { useTheme } from "../../plugins/styled";
import Button from "../common/button";
import Div from "../common/div";
import Typography from "../common/typography";

type ErrorNetworkProps = {
  errorMessage: string;
  navigate: (path: string | number, options?: any) => void;
  app: AppType;
};

const ErrorNetwork = ({ errorMessage, navigate }: ErrorNetworkProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const refreshPage = () => {
    errorMessage.toLowerCase().includes("state")
      ? navigate("/", { replace: true })
      : navigate(0);
  };

  if (!errorMessage) return <></>;

  return (
    <Div
      className="network-error"
      align="center"
      justify="center"
      style={{ height: "100vh" }}
      flex="column"
    >
      <DarwinError width={600} />

      <Div flex="column" gap={32} align="center">
        <Div flex="column" gap={8}>
          <Typography.Title
            textAlign="center"
            level={1}
            elementTheme="h4"
            noMargin
          >
            {t("errorsPage|problemPageTitle")}
          </Typography.Title>
          <Typography textAlign="center" elementTheme="body2">
            {t(`errorsPage|${errorMessage.replaceAll(" ", "")}`)}
          </Typography>
        </Div>
        <Button
          fitContent
          onClick={refreshPage}
          defaultStyle={theme.colors.grey_1}
        >
          {t("errorsPage|refresh")}
        </Button>
      </Div>
    </Div>
  );
};

export default ErrorNetwork;

