export type GrantStatus = "open" | "openingSoon" | "closed" | "closingSoon";

export interface Grant {
  grant_id: string;
  funding_max_amount?: number;
  funding_min_amount?: number;
  applicants_min_revenue_past_year?: number;
  applicants_max_revenue_past_year?: number;
  applicants_min_count_employee?: number;
  applicants_max_count_employee?: number;
  grant_created_at?: Date;
  grant_deadline?: string | null;
  grant_updated_at?: string | null;
  industry_sectors?: string[];
  percentage_funding?: number;
  application_status?: GrantStatus;
  financing_type?: string[];
  providers?: string[];
  application_phone_number?: string;
  application_email_address?: string;
  official_documents?: string;
  promoted_at?: Date;
  city?: string;
  region?: string;
  province?: string;
  grant_province?: string;
  country?: string;
  total_count?: number;
  rank?: number;
  service?: string[];
  size?: string;
  grant_industry_sectors?: string[];
  grant_financing_type?: string[];
  grant_providers?: string[];
  verified?: string;
  notes?: string;
  for_profit?: string[];
  grant_projects?: GrantProject[];
  grant_timeline?: GrantTimeline[];
  grant_display_title?: string;
  total_funding_available?: number;
  needed_investment?: number;
}

export interface GrantResult extends Grant {
  grant_title?: string;
  grant_display_title?: string;
  grant_description_short?: string;
  grant_description_long?: string;
  grant_url?: string;
  grant_urls?: string[];
  grant_slug?: string;
  grant_providers?: string[];
  grant_financing_type?: string[];
  grant_logo?: string;
  search_score?: string;
}

export interface WebsiteGrantResult extends Grant {
  grant_title?: string;
  grant_display_title?: string;
  grant_description_short?: string;
  grant_description_long?: string;
  grant_url?: string;
  grant_urls?: string[];
  grant_slug?: string;
  grant_youtube_url?: string;
  grant_providers?: string[];
  grant_financing_type?: string[];
  grant_logo?: string;
  eligibility_criteria?: string;
  eligible_expenses?: string;
  who_can_apply?: string;
  who_cannot_apply?: string;
  project_activity?: string;
  zone?: string;
  selection_criteria?: string;
  steps_how_to_apply?: string;
  additional_information?: string;
  documents_links?: string[];
}

export interface AdminGrantContentInfo {
  eligibility_criteria_en?: string;
  eligibility_criteria_fr?: string;
  eligible_expenses_en?: string;
  eligible_expenses_fr?: string;
  who_can_apply_en?: string;
  who_can_apply_fr?: string;
  who_cannot_apply_en?: string;
  who_cannot_apply_fr?: string;
  project_activity_en?: string;
  project_activity_fr?: string;
  zone_en?: string;
  zone_fr?: string;
  selection_criteria_en?: string;
  selection_criteria_fr?: string;
  steps_how_to_apply_en?: string;
  steps_how_to_apply_fr?: string;
  additional_information_en?: string;
  additional_information_fr?: string;
  documents_links_en?: string[];
  documents_links_fr?: string[];
  expanded_description_en?: string;
  expanded_description_fr?: string;
}

export interface GrantProject {
  grant_project_id: string;
  grant_id: string;
  locale: string;
  short_description: string;
  long_description: string;
  location: string;
  project_budget: number;
  amount_funded: number;
  deleted_at?: Date;
  business_type?: string;
  grant_project_tags?: GrantProjectTag[];
}

export interface AdminGrantResult extends Grant, AdminGrantContentInfo {
  grant_title_en?: string;
  grant_title_fr?: string;
  grant_display_title_en?: string;
  grant_display_title_fr?: string;
  grant_description_short_en?: string;
  grant_description_short_fr?: string;
  grant_description_long_en?: string;
  grant_description_long_fr?: string;
  grant_url_en?: string;
  grant_url_fr?: string;
  grant_urls_en?: string[];
  grant_urls_fr?: string[];
  grant_slug_en?: string;
  grant_slug_fr?: string;
  grant_youtube_url_en?: string;
  grant_youtube_url_fr?: string;
  grant_providers?: string[];
  grant_financing_type?: string[];
  grant_logo?: string;
  grant_service?: string[];
  grant_content_info?: AdminGrantContentInfo[];
  grant_projects_en?: GrantProject[];
  grant_projects_fr?: GrantProject[];
}
export declare const GrantInitialState: GrantResult;
export declare const GrantProviderInitialState: GrantProvider;

export interface GrantFinancingType {
  grant_financing_type_id: string;
  type: string;
}

export interface GrantService {
  grant_service_id: string;
  name: string;
}

export interface GrantForProfit {
  grant_for_profit_id: string;
  name: string;
}

export interface Province {
  code: string;
  name: string;
}

export interface GrantProvider {
  grant_provider_id: string;
  locale?: string;
  title?: string;
  description?: string;
  slug?: string;
  logo?: string;
  rank?: number;
  url?: string;
  acronym?: string;
  total_count?: number;
}
export interface GrantProviders {
  grant_provider_en: GrantProvider;
  grant_provider_fr: GrantProvider;
}
export interface GrantPreview {
  grant_id: string;
  display_title: string;
  verified: string;
}

export interface GrantApplication {
  id: string;
  grant_id: string;
  chat_id: string;
  content: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  completed_at: Date;
}

export interface GrantApplication {
  id: string;
  grant_id: string;
  chat_id: string;
  result: string;
  created_at: Date;
  updated_at: Date;
  completed_at: Date;
}

export interface GrantTimeline {
  grant_timeline_id?: string;
  grant_id: string;
  opened_at?: Date;
  closed_at?: Date;
  deleted_at?: Date;
}

export enum PromptType {
  Grant = "grant",
  Program = "program",
}

export interface GrantContentRaw {
  grant_content_raw_id: string;
  grant_content_id: string;
  grant_id: string;
  content: string;
  locale: string;
}

export interface GrantsVectorSearchResponse {
  id: string;
  grants: GrantResult[];
  extracted_filters: string[];
  explanation: string;
}

export enum TagType {
  Sector = "sector",
  ActivityType = "activity_type",
  Activity = "activity",
  EligibleExpense = "eligible_expense",
  None = "",
}
export interface GrantTag extends NewTag {
  id: string;
  grant_id: string;
}

export interface NewTag {
  tag_id: string;
  content: string;
  locale: string;
  tag_type: TagType;
  parent_id: string;
  parent?: NewTag;
}

export interface CreateTagRequest {
  id: string;
  content_fr: string;
  content_en: string;
  tag_type: TagType;
  parent_id: string;
}

export interface GrantProjectTag extends NewTag {
  id: string;
  grant_project_id: string;
}
export interface GrantTotalAmounts {
  financing_type: string;
  total_funding: number;
  label?: string;
}

