import { FormInstance } from "antd/es/form/hooks/useForm";
import { useCallback } from "react";
import usePrepareFundingExplorerFilters from "./prepare-filters";
import usePrepareFundingExplorerURL from "./prepare-url";
import useDebounceFunction from "../../../../features/hooks/use-debounce-function";
import { usePagination } from "../../../../features/providers/pagination-provider";

const FILTER_DELAY_MS = 500;

export interface QueryFundingExplorerProps {
  locale: string;
  page: number;
  limit: number;
  textQuery?: string;
  companyId?: string;
  filterBy?: string;
  industry?: string[];
  subindustry?: string[];
  status?: string[];
  financingType?: string[];
  region?: string[];
  closingDate?: Date;
  service?: string[];
  selectedProjects?: string[];
  annualRevenue?: string;
  companySize?: string;
}

interface ApplyFundingExplorerFilterProps {
  isReset?: boolean;
  isInit?: boolean;
}

type ApplyFundingExplorerFilterFunction = (
  props?: ApplyFundingExplorerFilterProps
) => Promise<void>;

interface UseApplyFundingExplorerFilterProps {
  fetch: () => void;
  form: FormInstance;
  selectedIndustries: string[];
  setSelectedIndustries: React.Dispatch<React.SetStateAction<string[]>>;
}

const useApplyFundingExplorerFilter = ({
  fetch,
  form,
  selectedIndustries,
  setSelectedIndustries,
}: UseApplyFundingExplorerFilterProps) => {
  const { params, addParamsToURL, activePage } = usePagination();

  const prepareFundingExplorerURL = usePrepareFundingExplorerURL({
    form,
    selectedIndustries,
  });
  const prepareFundingExplorerFilters = usePrepareFundingExplorerFilters({
    form,
    setSelectedIndustries,
  });

  return useDebounceFunction(
    useCallback(
      async (props?: ApplyFundingExplorerFilterProps) => {
        const isReset = props?.isReset ?? false;
        const isInit = props?.isInit ?? false;

        var pageFilter: number;

        if (form.isFieldsTouched() || isReset) {
          pageFilter = 1;
        } else {
          pageFilter = activePage;
        }

        if (isInit) {
          prepareFundingExplorerFilters();
          addParamsToURL();
        } else {
          prepareFundingExplorerURL(pageFilter);
        }

        fetch();
      },
      [
        params,
        selectedIndustries,
        setSelectedIndustries,
        fetch,
        form,
        addParamsToURL,
        activePage,
        prepareFundingExplorerURL,
        prepareFundingExplorerFilters,
      ]
    ),
    FILTER_DELAY_MS
  ) as ApplyFundingExplorerFilterFunction;
};

export default useApplyFundingExplorerFilter;

