import OnBoardingProvider, {
  OnBoardingStepTypes,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import useApolloClient from "@hellodarwin/core/lib/features/hooks/use-appollo-client";
import AppProvider from "@hellodarwin/core/lib/features/providers/app-provider";
import AppTranslationsProvider from "@hellodarwin/core/lib/features/providers/app-translations-provider";
import Auth0ProviderWithNavigate, {
  OauthOptions,
} from "@hellodarwin/core/lib/features/providers/auth0-provider-with-navigate";
import BreadcrumbsProvider from "@hellodarwin/core/lib/features/providers/breadcrumbs-provider";
import { LocaleProvider } from "@hellodarwin/core/lib/features/providers/locale-provider";
import PaginationProvider from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { UTMProvider } from "@hellodarwin/core/lib/features/providers/utm-provider";
import { ApolloProvider } from "@hellodarwin/core/lib/plugins/apollo";
import { AxiosError } from "@hellodarwin/core/lib/plugins/axios";
import { I18nextProvider } from "@hellodarwin/core/lib/plugins/i18n/provider";
import { ThemeProvider } from "@hellodarwin/core/lib/plugins/styled";
import theme from "@hellodarwin/core/lib/theme";
import getAntdTheme from "@hellodarwin/core/lib/theme/antd-theme";
import { GlobalTypographyStyling } from "@hellodarwin/core/lib/theme/global-style";
import ConfigProvider from "antd/es/config-provider";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./app/app-store";
import { fetchOauthOptions } from "./auth0/use-auth0";
import i18n from "./i18n";
import * as serviceWorker from "./serviceWorker";

const tagManagerArgs = {
  gtmId: "GTM-M97N8SC",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root")!);

const DefaultLayout = () => {
  const antdTheme = getAntdTheme(theme);
  const client = useApolloClient(
    "modxx330knqj",
    "P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8"
  );

  const [config, setConfig] = useState<OauthOptions>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    fetchOauthOptions("client")
      .then((config) => setConfig(config))
      .catch((err) => setError(err));
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalTypographyStyling />
        <ConfigProvider theme={antdTheme}>
          <BrowserRouter>
            <I18nextProvider i18n={i18n} defaultNS={"common"}>
              <LocaleProvider>
                <ApolloProvider client={client}>
                  <AppTranslationsProvider>
                    <Auth0ProviderWithNavigate
                      config={config}
                      error={error}
                      app="client"
                    >
                      <UTMProvider>
                        <BreadcrumbsProvider>
                          <PaginationProvider>
                            <AppProvider>
                              <OnBoardingProvider
                                stepType={OnBoardingStepTypes.Client}
                              >
                                <App />
                              </OnBoardingProvider>
                            </AppProvider>
                          </PaginationProvider>
                        </BreadcrumbsProvider>
                      </UTMProvider>
                    </Auth0ProviderWithNavigate>
                  </AppTranslationsProvider>
                </ApolloProvider>
              </LocaleProvider>
            </I18nextProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  );
};

root.render(
  <React.StrictMode>
    <DefaultLayout />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

