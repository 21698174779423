import Select, {
  BaseOptionType,
  DefaultOptionType,
  SelectProps,
} from "antd/es/select";
import styled, { keyframes } from "../../../plugins/styled";
import Grid from "../../common/hd-grid";

type AntdSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: React.PropsWithChildren<SelectProps<ValueType, OptionType>> &
    React.RefAttributes<any>
) => React.ReactElement;
export const FilterSelect: AntdSelect = styled(Select)`
  position: relative;
 
  &.ant-select-multiple,
  &.ant-select-multiple.ant-select-lg {
  
    .ant-select-selection-overflow-item {
      display: none;
      &.ant-select-selection-overflow-item-suffix {
        display: inline-flex;
        width: 100%;
        .ant-select-selection-search {
          width: 100% !important;
          input {
            font-size: 16px;
            width: 100%;
          }
        }
      }
    }
  }
  &.ant-select-multiple.ant-select-lg {
    .ant-select-selection-overflow-item {
      &.ant-select-selection-overflow-item-suffix {
        .ant-select-selection-search {
          input {
            font-size: 20px;
          }
        }
      }
    }
  }
    &.ant-select-multiple
    .ant-select-arrow {
      position: absolute;
      top: 0;
      left: 12px;
      margin: 0;
      height: 100%;
      width: calc(100% - 24px);
      }
      &.ant-select-open {
        .ant-select-arrow {
          opacity: 0;
          }
          }
          }

          @media screen and (max-width:${({ theme }) => theme.breakpoints.tablet}px){
          .ant-select {
          width:100% !important; }
          }
          `;
export const FilterSelectDropdown = styled.div`
  padding: 0;
  .ant-select-item {
    padding: 0;
  }
`;

export const FiltersGrid = styled(Grid)<{ $needSaving: boolean }>`
  ${({ $needSaving }) =>
    $needSaving
      ? `
& > .ant-col:last-child {
  max-width: calc((100% / 3) * 2);
  flex: 0 0 calc((100% / 3) * 2);
  padding: 12px 6px 0 54px !important;
  &..ant-col-24 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
`
      : undefined}
`;

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const ScrollingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(200% + 10px);
  animation: ${scroll} 40s linear infinite;
  animation-play-state: running;
`;

export const ScrollingContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: calc(100% + 48px);
  height: 60px;
  margin-left: -24px;
  margin-right: -24px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9),
      rgba(0, 0, 0, 0)
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.9),
      rgba(0, 0, 0, 0)
    );
  }

  &:hover ${ScrollingContent} {
    animation-play-state: paused;
  }
`;

export const FundingExplorerTagBarStyled = styled.div<{
  $isOpen?: boolean;
  $withDrawer: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;
  max-height: ${({ $isOpen, $withDrawer }) =>
    $isOpen || !$withDrawer ? "auto" : "100px"};
  overflow: hidden;
  position: relative;

  ${({ $isOpen, $withDrawer }) =>
    !$isOpen && $withDrawer
      ? `
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 24px;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.9)
      );
      }
      `
      : undefined}
`;

export const GrantProjectsRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  white-space: nowrap;
`;

export const FilterMultipleSelectStyled: AntdSelect = styled(Select)`
  .ant-select-selection-overflow,
  .ant-select-selection-overflow-item span {
    overflow: hidden;
  }
`;

export const FilterSectionContainterStyled = styled.div<{
  $position: "top" | "bottom";
  $navCollapsed?: boolean;
}>`
  z-index: 500;
  ${({ $position, theme, $navCollapsed }) =>
    $position === "top"
      ? `
    position: sticky;
    top: 0;
    @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
      position: relative;
    }
    `
      : `
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - ${$navCollapsed ? theme.appearance.sidebarWidthCollapsed : theme.appearance.sidebarWidth}px);
    transition: width 180ms linear;
      `}
`;

export const FilterSectionStyled = styled.div<{
  $position: "top" | "bottom";
}>`
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  background-color: ${({ theme }) => theme.colors.white_2};
  padding: 12px 24px;
  border-radius: ${({ $position }) => ($position === "top" ? "4px" : "0px")};
  box-shadow: ${({ theme, $position }) =>
    $position === "top"
      ? theme.appearance.box_shadow
      : theme.appearance.box_shadow_hover};
  backdrop-filter: blur(20px);
`;

export const FilterDropdownDesktopDrawerStyled = styled.div<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? "block" : "none")};
  position: absolute;
  top: calc(100% + 12px);
  backdrop-filter: blur(20px);
  width: 100%;
  left: 0;
  padding: 0;
  z-index: 200;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  background-color: ${({ theme }) => theme.colors.white_2};
  border-radius: 4px;
  overflow: hidden;
`;

